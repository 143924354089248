import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { MailmanagerService } from "../../api/services/mailmanager.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { MailmanagerCreateSieveRequest } from "../../api/models/mailmanager-create-sieve-request";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { MailmanagerSieveFilter } from "../../api/models/mailmanager-sieve-filter";
import { MailmanagerUpdateSieveRequest } from "../../api/models/mailmanager-update-sieve-request";
import { LoveItToastService } from "../../services/love-it-toast.service";

@Component({
  selector: "app-email-abwesenheitsbenachrichtigung",
  templateUrl: "./email-abwesenheitsbenachrichtigung.component.html",
  styleUrls: ["./email-abwesenheitsbenachrichtigung.component.scss"],
  standalone: false,
})
export class EmailAbwesenheitsbenachrichtigungComponent implements OnInit {
  @Input({ required: true })
  userId: string;
  @Input({ required: false })
  existingSieveFilter: MailmanagerSieveFilter;

  isSaving: boolean = false;
  submitted: boolean = false;

  sieveFilterFormGroup: UntypedFormGroup;

  constructor(
    private modalController: ModalController,
    private oidcSecurityService: OidcSecurityService,
    private mailmanagerService: MailmanagerService,
    private fb: FormBuilder,
    private loveItToastService: LoveItToastService,
  ) {}

  ngOnInit() {
    this.sieveFilterFormGroup = this.fb.group({
      id: [null],
      title: [null],
      subject: [null],
      message: [null],
      from: [new Date().toISOString().split("T")[0]],
      to: [new Date().toISOString().split("T")[0]],
    });
    if (this.existingSieveFilter) {
      this.sieveFilterFormGroup.patchValue({
        id: this.existingSieveFilter?.id,
        title: this.existingSieveFilter?.title,
        subject: this.existingSieveFilter?.subject,
        message: this.existingSieveFilter?.message,
        from: new Date(this.existingSieveFilter["is-active"]?.from)
          .toISOString()
          .split("T")[0],
        to: new Date(this.existingSieveFilter["is-active"]?.to)
          .toISOString()
          .split("T")[0],
      });
    }
  }

  createSieveFilter() {
    return new Promise<void>((resolve, reject) => {
      this.mailmanagerService
        .mailmanagerSieveCreateCreate({
          token: this.oidcSecurityService.getToken(),
          user_id: this.userId,
          sieve_filter: this.getCreateUpdateSieveFilterParams(),
        } as MailmanagerCreateSieveRequest)
        .subscribe({
          next: (response) => {
            resolve();
          },
          error: (error) => {
            const message = `Fehler beim Erstellen der Abwesenheitsbenachrichtigung!`;
            reject(new Error(message));
          },
        });
    });
  }

  updateSieveFilter() {
    return new Promise<void>((resolve, reject) => {
      this.mailmanagerService
        .mailmanagerSieveUpdateCreate({
          token: this.oidcSecurityService.getToken(),
          user_id: this.userId,
          filter_id: this.existingSieveFilter.id,
          sieve_filter: this.getCreateUpdateSieveFilterParams(),
        } as MailmanagerUpdateSieveRequest)
        .subscribe({
          next: (response) => {
            resolve();
          },
          error: (error) => {
            const message = `Fehler beim Speichern der Abwesenheitsbenachrichtigung!`;
            reject(new Error(message));
          },
        });
    });
  }

  getCreateUpdateSieveFilterParams() {
    return {
      id: this.sieveFilterFormGroup.get("id").value,
      title: this.sieveFilterFormGroup.get("title").value,
      subject: this.sieveFilterFormGroup.get("subject").value,
      message: this.sieveFilterFormGroup.get("message").value,
      is_active: {
        from_date: this.sieveFilterFormGroup.get("from").value,
        to_date: this.sieveFilterFormGroup.get("to").value,
      },
    } as MailmanagerSieveFilter;
  }

  cancelButton() {
    this.modalController.dismiss(null, "cancel");
  }

  submit() {
    this.isSaving = true;

    let currentPromise: Promise<void> = null;
    if (
      this.existingSieveFilter &&
      this.existingSieveFilter?.id === this.sieveFilterFormGroup.get("id").value
    ) {
      currentPromise = this.updateSieveFilter();
    } else {
      currentPromise = this.createSieveFilter();
    }

    currentPromise
      .then(() => {
        this.isSaving = false;
        this.modalController.dismiss(null, "confirm");
      })
      .catch(async (error: Error) => {
        this.isSaving = false;
        this.loveItToastService.showToastMessage(error.message);
      });
  }
}
