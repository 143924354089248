/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Textvorlagen } from '../models/textvorlagen';
@Injectable({
  providedIn: 'root',
})
class TextvorlagenService extends __BaseService {
  static readonly textvorlagenListPath = '/textvorlagen';
  static readonly textvorlagenCreatePath = '/textvorlagen';
  static readonly textvorlagenRetrieveFirstListPath = '/textvorlagen/retrieve-first';
  static readonly textvorlagenReadPath = '/textvorlagen/{id}/';
  static readonly textvorlagenUpdatePath = '/textvorlagen/{id}/';
  static readonly textvorlagenPartialUpdatePath = '/textvorlagen/{id}/';
  static readonly textvorlagenDeletePath = '/textvorlagen/{id}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TextvorlagenService.TextvorlagenListParams` containing the following parameters:
   *
   * - `kategorie`: kategorie
   *
   * - `benutzer`: benutzer
   */
  textvorlagenListResponse(params: TextvorlagenService.TextvorlagenListParams): __Observable<__StrictHttpResponse<Array<Textvorlagen>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.kategorie != null) __params = __params.set('kategorie', params.kategorie.toString());
    if (params.benutzer != null) __params = __params.set('benutzer', params.benutzer.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/textvorlagen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Textvorlagen>>;
      })
    );
  }
  /**
   * @param params The `TextvorlagenService.TextvorlagenListParams` containing the following parameters:
   *
   * - `kategorie`: kategorie
   *
   * - `benutzer`: benutzer
   */
  textvorlagenList(params: TextvorlagenService.TextvorlagenListParams): __Observable<Array<Textvorlagen>> {
    return this.textvorlagenListResponse(params).pipe(
      __map(_r => _r.body as Array<Textvorlagen>)
    );
  }

  /**
   * @param data undefined
   */
  textvorlagenCreateResponse(data: Textvorlagen): __Observable<__StrictHttpResponse<Textvorlagen>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/textvorlagen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Textvorlagen>;
      })
    );
  }
  /**
   * @param data undefined
   */
  textvorlagenCreate(data: Textvorlagen): __Observable<Textvorlagen> {
    return this.textvorlagenCreateResponse(data).pipe(
      __map(_r => _r.body as Textvorlagen)
    );
  }

  /**
   * @param params The `TextvorlagenService.TextvorlagenRetrieveFirstListParams` containing the following parameters:
   *
   * - `zuordnung`: Zuordnung (genannt 'Status' bei Emails)
   *
   * - `kategorie`: Kategorie
   *
   * - `benutzer`: Benutzer (SB Kürzel)
   *
   * @return Eine Textvorlage
   */
  textvorlagenRetrieveFirstListResponse(params: TextvorlagenService.TextvorlagenRetrieveFirstListParams): __Observable<__StrictHttpResponse<Textvorlagen>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.zuordnung != null) __params = __params.set('zuordnung', params.zuordnung.toString());
    if (params.kategorie != null) __params = __params.set('kategorie', params.kategorie.toString());
    if (params.benutzer != null) __params = __params.set('benutzer', params.benutzer.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/textvorlagen/retrieve-first`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Textvorlagen>;
      })
    );
  }
  /**
   * @param params The `TextvorlagenService.TextvorlagenRetrieveFirstListParams` containing the following parameters:
   *
   * - `zuordnung`: Zuordnung (genannt 'Status' bei Emails)
   *
   * - `kategorie`: Kategorie
   *
   * - `benutzer`: Benutzer (SB Kürzel)
   *
   * @return Eine Textvorlage
   */
  textvorlagenRetrieveFirstList(params: TextvorlagenService.TextvorlagenRetrieveFirstListParams): __Observable<Textvorlagen> {
    return this.textvorlagenRetrieveFirstListResponse(params).pipe(
      __map(_r => _r.body as Textvorlagen)
    );
  }

  /**
   * @param id A unique integer value identifying this textvorlagen.
   */
  textvorlagenReadResponse(id: number): __Observable<__StrictHttpResponse<Textvorlagen>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/textvorlagen/${encodeURIComponent(id)}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Textvorlagen>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this textvorlagen.
   */
  textvorlagenRead(id: number): __Observable<Textvorlagen> {
    return this.textvorlagenReadResponse(id).pipe(
      __map(_r => _r.body as Textvorlagen)
    );
  }

  /**
   * @param params The `TextvorlagenService.TextvorlagenUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this textvorlagen.
   *
   * - `data`:
   */
  textvorlagenUpdateResponse(params: TextvorlagenService.TextvorlagenUpdateParams): __Observable<__StrictHttpResponse<Textvorlagen>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/textvorlagen/${encodeURIComponent(params.id)}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Textvorlagen>;
      })
    );
  }
  /**
   * @param params The `TextvorlagenService.TextvorlagenUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this textvorlagen.
   *
   * - `data`:
   */
  textvorlagenUpdate(params: TextvorlagenService.TextvorlagenUpdateParams): __Observable<Textvorlagen> {
    return this.textvorlagenUpdateResponse(params).pipe(
      __map(_r => _r.body as Textvorlagen)
    );
  }

  /**
   * @param params The `TextvorlagenService.TextvorlagenPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this textvorlagen.
   *
   * - `data`:
   */
  textvorlagenPartialUpdateResponse(params: TextvorlagenService.TextvorlagenPartialUpdateParams): __Observable<__StrictHttpResponse<Textvorlagen>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/textvorlagen/${encodeURIComponent(params.id)}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Textvorlagen>;
      })
    );
  }
  /**
   * @param params The `TextvorlagenService.TextvorlagenPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this textvorlagen.
   *
   * - `data`:
   */
  textvorlagenPartialUpdate(params: TextvorlagenService.TextvorlagenPartialUpdateParams): __Observable<Textvorlagen> {
    return this.textvorlagenPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Textvorlagen)
    );
  }

  /**
   * @param id A unique integer value identifying this textvorlagen.
   */
  textvorlagenDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/textvorlagen/${encodeURIComponent(id)}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this textvorlagen.
   */
  textvorlagenDelete(id: number): __Observable<null> {
    return this.textvorlagenDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TextvorlagenService {

  /**
   * Parameters for textvorlagenList
   */
  export interface TextvorlagenListParams {

    /**
     * kategorie
     */
    kategorie?: string;

    /**
     * benutzer
     */
    benutzer?: string;
  }

  /**
   * Parameters for textvorlagenRetrieveFirstList
   */
  export interface TextvorlagenRetrieveFirstListParams {

    /**
     * Zuordnung (genannt 'Status' bei Emails)
     */
    zuordnung?: string;

    /**
     * Kategorie
     */
    kategorie?: string;

    /**
     * Benutzer (SB Kürzel)
     */
    benutzer?: string;
  }

  /**
   * Parameters for textvorlagenUpdate
   */
  export interface TextvorlagenUpdateParams {

    /**
     * A unique integer value identifying this textvorlagen.
     */
    id: number;
    data: Textvorlagen;
  }

  /**
   * Parameters for textvorlagenPartialUpdate
   */
  export interface TextvorlagenPartialUpdateParams {

    /**
     * A unique integer value identifying this textvorlagen.
     */
    id: number;
    data: Textvorlagen;
  }
}

export { TextvorlagenService }
