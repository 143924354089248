import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { OdataAktPersonResponse } from "../../api/models/odata-akt-person-response";
import { KontaktDetail } from "../../api/models/kontakt-detail";

@Component({
    selector: "app-kontaktdaten-ansicht-modal",
    templateUrl: "./kontaktdaten-ansicht-modal.component.html",
    styleUrls: ["./kontaktdaten-ansicht-modal.component.scss"],
    standalone: false
})
export class KontaktdatenAnsichtModalComponent {
  @Input() titel: string;
  @Input() enableEdit: boolean;
  @Input() anspruchsteller: string;
  @Input() auftrag: string;
  @Input() schadensnummer: string;
  @Input() fca: KontaktDetail;
  @Input() instance: KontaktDetail;
  @Input() akt_id: number;

  constructor(private modalController: ModalController) {}

  cancelButton() {
    this.modalController.dismiss(null, "cancel");
  }
}
