import { Component, OnDestroy, OnInit } from "@angular/core";

import { Platform } from "@ionic/angular";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Subscription } from "rxjs";
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent,
} from "ngx-cookieconsent";
import { AnalyticsService } from "./services/analytics.service";
import { AppComponentService } from "./services/app.component.service";

declare var gtag;

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  private userData = null;

  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private ccService: NgcCookieConsentService,
    private analyticsService: AnalyticsService,
    public appComponentService: AppComponentService,
  ) {
    const navEndEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      if (gtag) {
        const userId = this.userData ? this.userData.sb : "";
        gtag("config", "G-E2QPWG92DR", {
          page_path: event.urlAfterRedirects,
          user_id: userId,
        });
      }
    });

    this.oidcSecurityService.userData$.subscribe((userData) => {
      this.userData = userData;
    });
  }

  ngOnInit() {
    this.initCookieBanner();
  }

  private initCookieBanner() {
    if (window.location.hostname !== "fairapp.faircheck.at") {
      this.ccService.destroy();
      return;
    }
    const alreadyConsented = this.ccService.hasConsented();
    if (alreadyConsented) {
      this.analyticsService.allowCookies();
    } else {
      this.analyticsService.removeCookies();
    }
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status === "allow") {
          this.analyticsService.allowCookies();
        } else if (event.status === "deny") {
          this.analyticsService.removeCookies();
        }
      },
    );
  }

  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
