import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TextbausteinPlatzhalter } from "../enums/global-enums";

@Component({
    selector: "app-platzhalter-autofill-info-modal",
    templateUrl: "./platzhalter-autofill-info-modal.component.html",
    styleUrls: ["./platzhalter-autofill-info-modal.component.scss"],
    standalone: false
})
export class PlatzhalterAutofillInfoModalComponent {
  platzhalterList = Object.values(TextbausteinPlatzhalter);

  constructor(private modalController: ModalController) {}

  cancelButton() {
    this.modalController.dismiss(null, "cancel");
  }

  protected readonly TextbausteinPlatzhalter = TextbausteinPlatzhalter;
  protected readonly Object = Object;
}
