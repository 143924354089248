import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { MailmanagerService } from "../../api/services/mailmanager.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { MailmanagerUserIdRequest } from "../../api/models/mailmanager-user-id-request";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MailmanagerSieveFilter } from "../../api/models/mailmanager-sieve-filter";
import { MailmanagerListSieveResponse } from "../../api/models/mailmanager-list-sieve-response";
import { EmailAbwesenheitsbenachrichtigungComponent } from "../email-abwesenheitsbenachrichtigung/email-abwesenheitsbenachrichtigung.component";
import { MailmanagerUpdateSieveRequest } from "../../api/models/mailmanager-update-sieve-request";
import { MailmanagerDeleteSieveRequest } from "../../api/models/mailmanager-delete-sieve-request";
import { LoveItToastService } from "../../services/love-it-toast.service";

@Component({
  selector: "app-email-abwesenheitsbenachrichtigung",
  templateUrl: "./email-abwesenheitsbenachrichtigungen-list.component.html",
  styleUrls: ["./email-abwesenheitsbenachrichtigungen-list.component.scss"],
  standalone: false,
})
export class EmailAbwesenheitsbenachrichtigungenListComponent
  implements OnInit, AfterViewInit
{
  @Input({ required: true })
  userId: string;
  isSaving: boolean = false;
  loadSieveFilters: boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ["title", "subject", "from", "to", "delete"];
  dataSourceMatTable: MatTableDataSource<any>;

  constructor(
    private modalController: ModalController,
    private oidcSecurityService: OidcSecurityService,
    private mailmanagerService: MailmanagerService,
    private loveItToastService: LoveItToastService,
    private alertController: AlertController,
  ) {
    this.dataSourceMatTable = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.updateSieveFilterList();
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.sort = this.sort;
  }

  updateSieveFilterList() {
    this.loadSieveFilters = true;
    this.dataSourceMatTable.data = [];
    this.mailmanagerService
      .mailmanagerSieveRetrieveCreate({
        token: this.oidcSecurityService.getToken(),
        user_id: this.userId,
      } as MailmanagerUserIdRequest)
      .subscribe({
        next: (response: MailmanagerListSieveResponse) => {
          this.dataSourceMatTable.data = response.filters;
          this.loadSieveFilters = false;
        },
        error: async (error) => {
          this.loadSieveFilters = false;
          const message = `Fehler beim Laden der Abwesenheitsbenachrichtigungen!`;
          this.loveItToastService.showToastMessage(message);
        },
      });
  }

  deleteSieveFilter(filter: MailmanagerSieveFilter) {
    this.mailmanagerService
      .mailmanagerSieveDeleteCreate({
        token: this.oidcSecurityService.getToken(),
        user_id: this.userId,
        filter_id: filter.id,
      } as MailmanagerDeleteSieveRequest)
      .subscribe({
        next: (response) => {
          this.updateSieveFilterList();
        },
        error: async (error) => {
          const message = `Fehler beim Löschen der Abwesenheitsbenachrichtigung!`;
          this.loveItToastService.showToastMessage(message);
        },
      });
  }

  async deleteSieveFilterDialogAnzeigen(filter: MailmanagerSieveFilter) {
    const alert = await this.alertController.create({
      header: "Abwesenheitsbenachrichtigung löschen?",
      message: "Die Benachrichtung ist nach dem Löschen nicht mehr verfügbar.",
      buttons: [
        {
          cssClass: "color-primary",
          text: "Abbrechen",
          role: "cancel",
        },
        {
          cssClass: "color-danger",
          text: "Löschen",
          handler: () => {
            this.deleteSieveFilter(filter);
          },
        },
      ],
    });

    await alert.present();
  }

  cancelButton() {
    this.modalController.dismiss(null, "cancel");
  }

  submit() {
    this.isSaving = false;
    this.modalController.dismiss(null, "confirm");
  }

  onDatatableActivate(event: any, row) {
    if (event.target.id === "delete-sieve-filter-button") {
      return;
    }

    this.openEmailAbwesenheitsbenachrichtigungModal(row);
  }

  async openEmailAbwesenheitsbenachrichtigungModal(
    existingSieveFilter?: MailmanagerSieveFilter,
  ) {
    const modal = await this.modalController.create({
      component: EmailAbwesenheitsbenachrichtigungComponent,
      cssClass: "bigger-modal",
      componentProps: {
        userId: this.userId,
        existingSieveFilter: existingSieveFilter,
      },
    });

    modal.onDidDismiss().then((data) => {
      const d = data.data;
      if (d && d.keep) {
      }
    });

    await modal.present();
  }
}
