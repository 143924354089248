import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { TextvorlagenService } from "../api/services/textvorlagen.service";
import { TextkorrekturService } from "../api/services/textkorrektur.service";
import { Textvorlagen } from "../api/models/textvorlagen";
import { TerminStatus } from "../util/enums/global-enums";
import { PlatzhalterAutofillInfoModalComponent } from "../util/platzhalter-autofill-info-modal/platzhalter-autofill-info-modal.component";
import { translateQuillEditorElements } from "../util/global-functions/global-functions";

interface TextvorlagenOption {
  id: number;
  value: string;
  label: string;
}

@Component({
  selector: "app-textvorlage",
  templateUrl: "./textvorlage.component.html",
  styleUrls: ["./textvorlage.component.scss"],
  standalone: false,
})
export class TextvorlageComponent implements OnInit {
  @Input() content;
  @Input() field;
  @Input() allowTextbausteinEditing: boolean = true;
  @Input() useResult: boolean = true;
  @Input() zuordnung: string;
  formGroup: UntypedFormGroup;
  username;
  textvorlagen: Textvorlagen[];
  textvorlagenOptions: TextvorlagenOption[];
  saveAsTemplateDisabled = true;
  cursorPosition = null;
  korrigierterText = null;
  korrekturVorschau = null;
  isTextvorlagenUpdate = false;

  width: number = 0;
  minWidth: number = 0;
  height: number = 0;
  minHeight: number = 0;
  resizing: boolean = false;
  lastMouseX: number = 0;
  lastMouseY: number = 0;

  emailStatusDropdownOptions: {
    value: string | null;
    text: string;
  }[] = [
    {
      value: null,
      text: "für alle E-Mails verfügbar",
    },
    {
      value: TerminStatus.Vereinbart.toString(),
      text: "wurde vereinbart",
    },
    {
      value: TerminStatus.SpaeterVNNichtErreicht.toString(),
      text: "spätere Vereinbarung (VN nicht erreicht)",
    },
    {
      value: TerminStatus.NichtMoeglichPostweg.toString(),
      text: "Vereinbarung nicht möglich -> Postweg (BO)",
    },
    {
      value: TerminStatus.GeraetAufPostweg.toString(),
      text: "Gerät auf dem Postweg",
    },
    {
      value: TerminStatus.PoststueckEingelangt.toString(),
      text: "Poststück eingelangt",
    },
    {
      value: TerminStatus.SpaeterMoeglich.toString(),
      text: "Vereinbarung erst später möglich",
    },
  ];

  quillEditorModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  constructor(
    private modalController: ModalController,
    private fb: UntypedFormBuilder,
    private oidcSecurityService: OidcSecurityService,
    private textvorlagenService: TextvorlagenService,
    private alertController: AlertController,
    private textkorrekturService: TextkorrekturService,
    private el: ElementRef,
    protected renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.username = this.oidcSecurityService.getPayloadFromIdToken().sb;
    this.formGroup = this.fb.group({
      fg_content: this.content,
      template_name: null,
      template_zuordnung: null,
      selected_vorlage: null,
      fg_content_korrektur: this.korrigierterText,
    });
    this.getTextvorlagen();

    this.formGroup.valueChanges.subscribe((v) => {
      this.saveAsTemplateDisabled =
        v.template_name === "" ||
        v.template_name === null ||
        v.fg_content === null ||
        v.fg_content === "";
    });

    this.formGroup.controls.template_name.valueChanges.subscribe(() => {
      this.updateIsTextvorlagenUpdate();
    });

    const modalElement = this.el.nativeElement.closest(".textvorlageModal");
    const computedStyle = getComputedStyle(modalElement);
    this.width = parseFloat(computedStyle.getPropertyValue("--width").trim());
    this.height = parseFloat(computedStyle.getPropertyValue("--height").trim());
    this.minWidth = parseFloat(
      computedStyle.getPropertyValue("--min-width").trim(),
    );
    this.minHeight = parseFloat(
      computedStyle.getPropertyValue("--min-height").trim(),
    );
  }

  private get currentTextvorlage() {
    const textvorlageName = this.formGroup.controls.template_name.value;
    return this.textvorlagen.find((e) => e.name === textvorlageName);
  }

  private updateIsTextvorlagenUpdate() {
    this.isTextvorlagenUpdate = this.currentTextvorlage !== undefined;
  }

  private getTextvorlagen() {
    this.textvorlagenService
      .textvorlagenList({
        benutzer: this.username,
        kategorie: this.field.toLowerCase(),
      })
      .subscribe((textvorlagen: Textvorlagen[]) => {
        this.textvorlagen = textvorlagen.sort((a, b) => {
          const nameA = a.name?.toLowerCase() ?? "";
          const nameB = b.name?.toLowerCase() ?? "";

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        if (this.zuordnung) {
          this.textvorlagen = this.textvorlagen.filter(
            (vorlage) =>
              vorlage.zuordnung?.toLowerCase() ===
                this.zuordnung?.toLowerCase() || !vorlage.zuordnung,
          );
        }

        this.textvorlagenOptions = this.textvorlagen.map((textvorlage) => {
          return {
            id: textvorlage.id,
            value: textvorlage.inhalt,
            label: `${textvorlage.name} - ${textvorlage.inhalt}`,
          } as TextvorlagenOption;
        });
      });
  }

  uebernehmen() {
    this.modalController.dismiss({
      keep: true,
      content: this.formGroup.controls.fg_content.value,
    });
  }

  cancel() {
    this.modalController.dismiss({ keep: false, content: "" });
  }

  updateTextVorlage() {
    const content = this.formGroup.controls.fg_content.value;
    const templateName = this.formGroup.controls.template_name.value;
    const templateZuordnung =
      this.formGroup.controls.template_zuordnung?.value ?? null;

    const params = {
      benutzer: this.username,
      inhalt: content,
      name: templateName,
      kategorie: this.field.toLowerCase(),
      zuordnung: templateZuordnung,
    };
    if (content !== "" && templateName !== null && templateName !== "") {
      if (this.isTextvorlagenUpdate) {
        const updateParams = { id: this.currentTextvorlage.id, data: params };
        this.textvorlagenService
          .textvorlagenUpdateResponse(updateParams)
          .subscribe((d) => {
            this.getTextvorlagen();
            if (!this.useResult) {
              this.modalController.dismiss(null, "confirm");
            }
          });
      } else {
        this.textvorlagenService.textvorlagenCreate(params).subscribe((d) => {
          this.getTextvorlagen();
          if (!this.useResult) {
            this.modalController.dismiss(null, "confirm");
          }
        });
      }
    }
  }

  vorlageChange() {
    const selected = this.formGroup.controls.selected_vorlage?.value?.id;
    const vorlageObj = this.textvorlagen.filter((f) => {
      return f.id === selected;
    })[0];
    if (this.formGroup.controls.fg_content.value) {
      const cursorPosition =
        this.cursorPosition ?? this.formGroup.controls.fg_content.value.length;
      const newContent = [
        this.formGroup.controls.fg_content.value.slice(0, cursorPosition),
        vorlageObj.inhalt,
        this.formGroup.controls.fg_content.value.slice(cursorPosition),
      ].join("");
      this.formGroup.controls.fg_content.patchValue(newContent);
    } else {
      this.formGroup.controls.fg_content.patchValue(vorlageObj.inhalt);
    }
    this.refreshTextbausteinData();
  }

  refreshTextbausteinData() {
    const selected = this.formGroup.controls.selected_vorlage?.value?.id;
    const vorlageObj = this.textvorlagen.filter((f) => {
      return f.id === selected;
    })[0];
    this.formGroup.controls.template_name.patchValue(vorlageObj?.name ?? null);
    this.formGroup.controls.template_zuordnung.patchValue(
      vorlageObj?.zuordnung ?? null,
    );
  }

  async deleteVorlage() {
    const alert = await this.alertController.create({
      header: "Textbaustein wirklich löschen?",
      message:
        "Bitte bestätigen Sie das Löschen des Textbausteins. Der Textbaustein steht Ihnen nach dem Löschen nicht mehr zur Verfügung!",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "color-primary",
        },
        {
          cssClass: "color-danger",
          text: "Löschen",
          handler: () => {
            this.textvorlagenService
              .textvorlagenDelete(
                this.formGroup.controls.selected_vorlage.value.id,
              )
              .subscribe((p) => {
                this.getTextvorlagen();
                this.formGroup.controls.selected_vorlage.patchValue(null);
                this.formGroup.controls.template_name.patchValue(null);
              });
          },
        },
      ],
    });

    await alert.present();
  }

  getPos($event, textAreaInput) {
    this.cursorPosition = $event.target.selectionEnd;
  }

  async korrigiereText() {
    const val = this.formGroup.controls.fg_content.value;
    this.korrekturVorschau = null;
    this.korrigierterText = null;
    this.textkorrekturService
      .textkorrekturCreate({ Txt_Raw: val })
      .subscribe((res) => {
        this.formGroup.controls.fg_content_korrektur.patchValue(
          res.Txt_Corrected,
        );
        this.content = res.Txt_Raw;
        this.korrigierterText = res.Txt_Corrected;
        this.korrekturVorschau = JSON.parse(res.Txt_Corrected_Vorschau);
        this.scrollToKorrekturVorschau();
      });
  }

  scrollToKorrekturVorschau(): void {
    setTimeout(() => {
      const element = document.getElementById("korrektur-vorschau-target");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  }

  korrekturUebernehmen() {
    this.formGroup.patchValue({
      fg_content: this.korrigierterText,
      fg_content_korrektur: null,
    });
    this.korrigierterText = null;
    this.korrekturVorschau = null;
  }

  korrekturVerwerfen() {
    this.formGroup.patchValue({
      fg_content_korrektur: null,
      fg_content: this.content,
    });
    this.korrigierterText = null;
    this.korrekturVorschau = null;
  }

  startResizing(event: MouseEvent): void {
    this.resizing = true;
    this.lastMouseX = event.clientX;
    this.lastMouseY = event.clientY;
  }

  @HostListener("document:mousemove", ["$event"])
  onMouseMove(event: MouseEvent): void {
    if (this.resizing) {
      const deltaX = event.clientX - this.lastMouseX;
      const deltaY = event.clientY - this.lastMouseY;
      this.width += deltaX;
      if (this.width < this.minWidth) {
        this.width = this.minWidth;
      }
      this.height += deltaY;
      if (this.height < this.minHeight) {
        this.height = this.minHeight;
      }
      this.lastMouseX = event.clientX;
      this.lastMouseY = event.clientY;
      this.updateModalSize();
    }
  }

  @HostListener("document:mouseup")
  onMouseUp(): void {
    this.resizing = false;
  }

  updateModalSize(): void {
    const modalElement = this.el.nativeElement.closest(".textvorlageModal");
    this.renderer.setStyle(modalElement, "--width", `${this.width}px`, 2);
    this.renderer.setStyle(modalElement, "--height", `${this.height}px`, 2);
  }

  async openTextbausteinPlatzhalterModal() {
    const modal = await this.modalController.create({
      component: PlatzhalterAutofillInfoModalComponent,
      cssClass: "smaller-modal",
    });

    await modal.present();
  }

  protected readonly translateQuillEditorElements =
    translateQuillEditorElements;
}
