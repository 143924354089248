import { Component, Input, OnInit } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Router } from "@angular/router";
import { PopoverController } from "@ionic/angular";
import { BackService } from "../../services/back.service";
import { AuthService } from "../../services/auth.service";
import { UserRole } from "../enums/global-enums";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    standalone: false
})
export class HeaderComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  showBackButton: boolean = true;

  userData: any;
  enableDarkmode = false;
  uniquePopoverID = "";

  readonly localStorageDarkModeSettingKey = "fairapp_darkskin";

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private popoverController: PopoverController,
    private backService: BackService,
    public router: Router,
    public authService: AuthService,
  ) {
    this.uniquePopoverID = Math.random().toString(36).substring(7);
  }

  ngOnInit() {
    this.oidcSecurityService.userData$.subscribe((userData) => {
      this.userData = userData;
    });

    const systemPrefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    const darkEnabled =
      localStorage.getItem(this.localStorageDarkModeSettingKey) === null
        ? systemPrefersDark.matches
        : this.loadThemeSettingFromLocalStorage();
    this.saveThemeSettingToLocalStorage(darkEnabled);
    this.setDarkMode(darkEnabled);
  }

  toggleDarkTheme() {
    const darkEnabled = !this.loadThemeSettingFromLocalStorage();
    this.saveThemeSettingToLocalStorage(darkEnabled);
    this.setDarkMode(darkEnabled);
  }

  saveThemeSettingToLocalStorage(darkModeEnabled: boolean) {
    localStorage.setItem(
      this.localStorageDarkModeSettingKey,
      String(darkModeEnabled),
    );
  }

  loadThemeSettingFromLocalStorage() {
    return JSON.parse(
      localStorage.getItem(this.localStorageDarkModeSettingKey),
    );
  }

  setDarkMode(enabled: boolean) {
    this.enableDarkmode = enabled;
    document.body.classList.toggle("dark-layout", enabled);
    document.body.classList.toggle("default-layout", !enabled);
  }

  getUsername() {
    return `${this.userData?.sb}`;
  }

  goBack() {
    this.router.navigate([this.backService.backPath], { replaceUrl: true });
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  dismissPopover() {
    this.popoverController.dismiss();
  }

  protected readonly UserRole = UserRole;
}
