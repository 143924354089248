/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OdataAktOrtResponse } from '../models/odata-akt-ort-response';
import { OdataAktOrtSaveRequest } from '../models/odata-akt-ort-save-request';
import { FairAppSimpleRequest } from '../models/fair-app-simple-request';
import { AllowedResponse } from '../models/allowed-response';
import { OdataGeneralAktRequest } from '../models/odata-general-akt-request';
import { OdataAktPersonResponse } from '../models/odata-akt-person-response';
@Injectable({
  providedIn: 'root',
})
class AktService extends __BaseService {
  static readonly aktAktortOdataBesichtigungsortChangeCreatePath = '/akt/aktort/odata/besichtigungsort/change';
  static readonly aktAktortOdataPatchCreatePath = '/akt/aktort/odata/patch';
  static readonly aktAktortOdataRetrieveCreatePath = '/akt/aktort/odata/retrieve';
  static readonly aktIsUploadAllowedCreatePath = '/akt/is-upload-allowed';
  static readonly aktOdataRetrieveCreatePath = '/akt/odata/retrieve';
  static readonly aktVersicherungOdataRetrieveCreatePath = '/akt/versicherung/odata/retrieve';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  aktAktortOdataBesichtigungsortChangeCreateResponse(data: OdataAktOrtSaveRequest): __Observable<__StrictHttpResponse<OdataAktOrtResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/akt/aktort/odata/besichtigungsort/change`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataAktOrtResponse>;
      })
    );
  }
  /**
   * @param data undefined
   */
  aktAktortOdataBesichtigungsortChangeCreate(data: OdataAktOrtSaveRequest): __Observable<OdataAktOrtResponse> {
    return this.aktAktortOdataBesichtigungsortChangeCreateResponse(data).pipe(
      __map(_r => _r.body as OdataAktOrtResponse)
    );
  }

  /**
   * @param data undefined
   */
  aktAktortOdataPatchCreateResponse(data: OdataAktOrtSaveRequest): __Observable<__StrictHttpResponse<OdataAktOrtSaveRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/akt/aktort/odata/patch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataAktOrtSaveRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  aktAktortOdataPatchCreate(data: OdataAktOrtSaveRequest): __Observable<OdataAktOrtSaveRequest> {
    return this.aktAktortOdataPatchCreateResponse(data).pipe(
      __map(_r => _r.body as OdataAktOrtSaveRequest)
    );
  }

  /**
   * @param data undefined
   */
  aktAktortOdataRetrieveCreateResponse(data: FairAppSimpleRequest): __Observable<__StrictHttpResponse<Array<OdataAktOrtResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/akt/aktort/odata/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OdataAktOrtResponse>>;
      })
    );
  }
  /**
   * @param data undefined
   */
  aktAktortOdataRetrieveCreate(data: FairAppSimpleRequest): __Observable<Array<OdataAktOrtResponse>> {
    return this.aktAktortOdataRetrieveCreateResponse(data).pipe(
      __map(_r => _r.body as Array<OdataAktOrtResponse>)
    );
  }

  /**
   * @param data undefined
   */
  aktIsUploadAllowedCreateResponse(data: FairAppSimpleRequest): __Observable<__StrictHttpResponse<AllowedResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/akt/is-upload-allowed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllowedResponse>;
      })
    );
  }
  /**
   * @param data undefined
   */
  aktIsUploadAllowedCreate(data: FairAppSimpleRequest): __Observable<AllowedResponse> {
    return this.aktIsUploadAllowedCreateResponse(data).pipe(
      __map(_r => _r.body as AllowedResponse)
    );
  }

  /**
   * @param data undefined
   */
  aktOdataRetrieveCreateResponse(data: OdataGeneralAktRequest): __Observable<__StrictHttpResponse<OdataGeneralAktRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/akt/odata/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataGeneralAktRequest>;
      })
    );
  }
  /**
   * @param data undefined
   */
  aktOdataRetrieveCreate(data: OdataGeneralAktRequest): __Observable<OdataGeneralAktRequest> {
    return this.aktOdataRetrieveCreateResponse(data).pipe(
      __map(_r => _r.body as OdataGeneralAktRequest)
    );
  }

  /**
   * @param data undefined
   */
  aktVersicherungOdataRetrieveCreateResponse(data: FairAppSimpleRequest): __Observable<__StrictHttpResponse<OdataAktPersonResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/akt/versicherung/odata/retrieve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdataAktPersonResponse>;
      })
    );
  }
  /**
   * @param data undefined
   */
  aktVersicherungOdataRetrieveCreate(data: FairAppSimpleRequest): __Observable<OdataAktPersonResponse> {
    return this.aktVersicherungOdataRetrieveCreateResponse(data).pipe(
      __map(_r => _r.body as OdataAktPersonResponse)
    );
  }
}

module AktService {
}

export { AktService }
