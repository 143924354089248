import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class LoveItToastService {
  constructor(private toastController: ToastController) {}

  showToastMessage(message: string, errorCode?: number) {
    const toastPromise = this.toastController.create({
      message: `${message}`,
      duration: 0,
      cssClass: "interactive-toast",
    });

    const toastTimeMilliseconds: number =
      errorCode >= 400 && errorCode < 600 ? 10000 : 4000;
    let toastTimeout: any;
    toastPromise.then((toast) => {
      toast.present();

      const closeToast = () => {
        toastTimeout = setTimeout(() => {
          toast.dismiss();
        }, toastTimeMilliseconds);
      };

      const toastElement = document.querySelector(".interactive-toast");
      toastElement?.addEventListener("mouseenter", () => {
        if (toastTimeout) {
          clearTimeout(toastTimeout);
        }
      });

      toastElement?.addEventListener("mouseleave", () => {
        closeToast();
      });

      toastElement?.addEventListener("click", () => {
        toast.dismiss();
        if (toastTimeout) {
          clearTimeout(toastTimeout);
        }
      });

      closeToast();
    });
  }
}
