// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-column-from {
  width: 120px;
  max-width: 120px;
}

.mat-column-to {
  width: 120px;
  max-width: 120px;
}

.mat-column-delete {
  width: 90px;
  max-width: 90px;
}`, "",{"version":3,"sources":["webpack://./src/app/util/email-abwesenheitsbenachrichtigungen-list/email-abwesenheitsbenachrichtigungen-list.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;AACF","sourcesContent":[".mat-column-from {\n  width: 120px;\n  max-width: 120px;\n}\n\n.mat-column-to {\n  width: 120px;\n  max-width: 120px;\n}\n\n.mat-column-delete {\n  width: 90px;\n  max-width: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
