import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TerminArt } from "../../details/details.page";
import { IonModal } from "@ionic/angular";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TermineService } from "../../api/services/termine.service";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Component({
    selector: "app-termin-hinzufuegen",
    templateUrl: "./termin-hinzufuegen.component.html",
    styleUrls: ["./termin-hinzufuegen.component.scss"],
    standalone: false
})
export class TerminHinzufuegenComponent implements OnInit {
  @Input() aktId: number;
  @Input() terminArt: TerminArt;
  @Input() afterTerminhinzufuegen?: (...args: unknown[]) => unknown;

  @ViewChild("terminHinzufuegenModal") terminHinzufuegenModal: IonModal;
  terminHinzufuegenFormGroup: UntypedFormGroup;
  isSaving: boolean = false;

  betreffOptions = [{ label: "Kundenkontaktversuch" }];

  constructor(
    private fb: UntypedFormBuilder,
    private termineService: TermineService,
    private oidcSecurityService: OidcSecurityService,
  ) {}

  ngOnInit() {
    this.terminHinzufuegenFormGroup = this.fb.group({
      Betreff: [null, Validators.required],
      Bemerkung: [null, Validators.required],
    });
  }

  getTerminTitleByTerminArt(terminArt: TerminArt): string {
    switch (terminArt) {
      case TerminArt.Kalendierung:
        return "Termin";
      case TerminArt.Kommentar:
        return "Kommentar";
      default:
        return "";
    }
  }

  openTerminHinzufuegenModal() {
    this.terminHinzufuegenFormGroup.reset();
    this.isSaving = false;
    this.terminHinzufuegenModal.present();
  }

  cancelButtonTerminHinzufuegen() {
    this.terminHinzufuegenModal.dismiss(null, "cancel");
  }

  submit() {
    if (this.terminHinzufuegenFormGroup.invalid) {
      this.terminHinzufuegenFormGroup.markAllAsTouched();
      return;
    }
    this.saveTermin();
  }

  async saveTermin() {
    this.isSaving = true;
    this.termineService
      .termineOdataCreateCreate({
        token: this.oidcSecurityService.getToken(),
        AktId: this.aktId,
        Art: this.terminArt,
        Betreff: this.terminHinzufuegenFormGroup.get("Betreff").value,
        Bemerkung: this.terminHinzufuegenFormGroup.get("Bemerkung").value,
        Ganztag: true,
      })
      .subscribe(() => {
        this.isSaving = false;
        this.terminHinzufuegenModal.dismiss(null, "confirm");
        this.afterTerminhinzufuegen.call(null);
      });
  }

  protected readonly TerminArt = TerminArt;
}
